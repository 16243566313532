.header {
    position: fixed;
    width: 100%;
    background-color: #354044;
    z-index: 1000;
  }
  .logo {
    margin-top: 5%;
    border-radius: 10%;
    width: auto;
    height: 30px;
  }
  .headerFont {
    color: #ffffff !important;
    margin-left: 1.5em;
    font-weight: 400;
  }