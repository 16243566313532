
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

.fundoMainFrame {
  padding-top: 14em;
  background-image: url("../img/mainFrame.webP");
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;
}
.textos p{
  padding-bottom: 3em;
}
.textos h1{
  font-weight: 900;
}
.textos h3{
  padding: 5px;
  font-weight: 200;
  border-bottom: solid;
  font-family: 'Open Sans', sans-serif;
} 
.textos h6{
  font-weight: 200;
  font-family: 'Open Sans', sans-serif;
}
.textos {
  color: #ffffff !important;
  text-align: center;
}
.btnCTA{
  margin-top: 4em;
}
.projetosContainer h3, .avContainer h3{
  text-align: center;
  font-weight: 300;
  padding: 3em 0 1em 0;
}
.linhas{
  padding-top: 1em;
  padding-bottom: 6em;
  text-align: center;
}
.colunaTxt{
  text-align: justify;
}
.btnSaibaMais{
  text-align: center;
  padding: 0.5em;
}
.colunaImgDir{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colunaImgEsq{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.colunaImgDir .img, .colunaImgEsq .img{
  max-width: 35vw;
  border-radius: 1em;
}
.imgSobre{
  width: auto;
  max-height: 70vh;
  border-radius: 1em;
}
.linhaSobre{
  padding-bottom: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sobreContainer{
  background-color: #354044;
  margin-top: 2em;
}
.sobreContainer h3{
  text-align: center;
  font-weight: 300;
  padding: 3em;
  color: #ffffff;
}
.colunaTxtSobre{
  text-align: justify;
  color: #ffffff;
}
.depoimentos{
  display: flex;
  justify-content: center;
  align-items: center;
}
.linhaDepoimentos{
  padding-bottom: 2em;
  text-align: center;
}
.depoimento , .projeto{
  background-color: #e1e1e189;
  padding: 2em 1em 2em 1em;
  border-radius: 1em;
}
.depoimento img{
  padding: 2em;
  max-width: 35vh;
  border-radius: 50%;
}
.depoimento h5 , .projeto h5{
  padding: 2em;
}
.depoimento p , .projeto p{
  text-align: justify;
}
.fixedButton{
  position: fixed;
  bottom: 20px; /* Distância do fundo */
  right: 20px;   /* Distância da esquerda */
  border: none;
  cursor: pointer;
  width: auto;
  height: 6vh;
}
.footer{
  background-color: #354044;
  min-height: 30vh;
}
.linhaFooter{
  padding-top: 4em;
}
.colunaFooter{
  text-align: center;
}
.colunaFooter img{
  height: auto;
  width: 30vh;
  padding: 10px;
}
.colunaFooter h5, .colunaFooter p{
  color: #ffffff;
}

.colunaFooter p {
  font-size: 0.8em; 
  padding: 0;
}
.socialIcons {
  display: flex;
  flex-direction: column;
}
.socialIcons a {
  padding: 5px;
  color: white; /* Cor do texto branca */
  text-decoration: none; /* Espaçamento entre os ícones */
}

.projeto img{
  padding: 2em;
  width: auto;
  height: 50vh;
}

.formContato{
  min-height: 70vh;
  padding-top: 7vh;
  display: flex;
  align-items: center;
}
.containerForm{
  background-color: #e1e1e189;
  padding: 2em 1em 2em 1em;
  border-radius: 1em;
}
.formContato h3{
  text-align: center;
  font-weight: 300;
}
.btnEnviar{
  display: flex;
  justify-content: center;
}
.button {
  border-radius: 1em;
  padding: 1em;
}